import Splitting from 'splitting'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

class autocomplete {
  constructor(autocomplete) {
    this.autocomplete = autocomplete

    this.init()
  }

  truncate() {
    const links = this.autocomplete.querySelectorAll('.SearchAutocomplete-link')

    links.forEach(link => {
      const splittedText = Splitting({
        target: link,
        by: 'lines'
      })

      if (splittedText[0].lines.length > 1) {
        link.classList.add('SearchAutocomplete-link--truncated')
      }
    })
  }

  renderAutocomplete(data) {
    const autocompleteResults = this.autocomplete.querySelector('[data-autocomplete-results]');
    const autocompleteResultsList = autocompleteResults.querySelector('ul')

    autocompleteResultsList.innerHTML = ''

    data.forEach(result => {
      const listItem = document.createElement('li')
      const link = document.createElement('a')

      listItem.classList.add('SearchAutocomplete-item')
      link.classList.add('SearchAutocomplete-link')

      link.innerText = result.text
      link.setAttribute('href', result.url)

      listItem.appendChild(link)
      autocompleteResultsList.appendChild(listItem)
    })
  }

  resultsFetch(e) {
    const searchInput = e.target
    const searchContentType = this.autocomplete.getAttribute('data-search-autocomplete')

    if (searchInput.value.length < 3) return

    fetch(`/autocomplete/${searchContentType}/${encodeURI(searchInput.value)}`)
      .then(response => response.json())
      .then(data => this.renderAutocomplete(data))
  }

  typeSearch() {
    const searchInput = this.autocomplete.querySelector('[data-search-input]')
    let typingTimer

    searchInput.addEventListener('keyup', (e) => {
      clearTimeout(typingTimer)
      typingTimer = setTimeout(() => {
        this.resultsFetch(e)
      }, 400)
    })
  }

  init() {
    this.typeSearch()
    this.truncate()
  }
}

function init() {
  const autocompleteBlocks = document.querySelectorAll('[data-search-autocomplete]')

  if (!autocompleteBlocks.length) return

  autocompleteBlocks.forEach(autocompleteBlock => {
    const autocompleteInit = new autocomplete(autocompleteBlock)
  })
}
