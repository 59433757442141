import { mapStick } from './mapstick'
import { initHomeForm, initSearchForm } from './mapforms'
import { initGeocoder } from './mapforms'
import { initFilters } from './mapfilters'
import { createMap } from './mapfunctions'


if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

const store = {
  structures: [],
  farthestStructure: null,
  listedStructureCount: 0,
  filters: [],
  markers: [],
  infoWindows: [],
  startLocation: {},
  input: {
    address: '',
    region: ''
  }
}

const mapParams = {
  zoom: {
    max: 16,
    min: 6,
    current: null
  },
  mapCenter: {
    lng: 2.213749,
    lat: 46.227638
  }
}

const listParams = {
  currentPage: 0,
  limitPerPage: 10,
  pageNb: null
}

function init() {
  mapStick()
  createMap()
  initGeocoder()
  initHomeForm()
  initSearchForm()
  initFilters()
}

export { listParams, mapParams, store }
