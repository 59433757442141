// Function to add transparency filter to a video
import videoTransparency from './videotransarency'

// Function to link slider and video
import sliderVideo from './slidervideo'


if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  timelineSlider()
  displayVideo()
}

function timelineSlider() {
  const timeline = document.querySelector('[data-timeline]')

  if (!timeline) return

  const sliderVideoInit = new sliderVideo(timeline);
}

function displayVideo() {
  const videoContainer = document.querySelector('[data-timeline-animation]')

  if (!videoContainer) return

  const timelineInit = new videoTransparency(videoContainer)
}
