import Swiper, { EffectFade } from 'swiper'

Swiper.use([EffectFade])

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const callback = document.querySelector('[data-formCallback]')

  if (!callback) return

  const callbackSlider = callback.querySelector('[data-callback-slider]')
  const nextButtons = callback.querySelectorAll('[data-callback-next]')
  const previousButton = callback.querySelector('[data-callback-previous]')

  const callbackSliderOptions = {
    speed: 450,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    allowTouchMove: false
  }

  const callbackSliderInit = new Swiper(callbackSlider, callbackSliderOptions)

  nextButtons.forEach(nextButton => {
    nextButton.addEventListener('click', () => {
      callbackSliderInit.slideNext()
    })
  })

  previousButton.addEventListener('click', () => {
    callbackSliderInit.slidePrev()
  })

  callbackSliderInit.on('slideChange', () => {
    if (callbackSliderInit.activeIndex > 0) {
      previousButton.classList.add('Callback-previous--is-visible')
    } else {
      previousButton.classList.remove('Callback-previous--is-visible')
    }
  })

  sliderSteps(callback)
  sliderErrors(callbackSliderInit)
}

function sliderSteps(sliderContainer) {
  const slides = sliderContainer.querySelectorAll('.swiper-slide')

  slides.forEach(slide => {
    if (!slide.querySelector('.Callback-input').value.length) {
      slide.querySelector('button').setAttribute('disabled', '')
    } else {
      slide.querySelector('button').removeAttribute('disabled')
    }

    slide.querySelector('.Callback-input').addEventListener('input', function() {
      if (this.value.length) {
        slide.querySelector('button').removeAttribute('disabled')
      } else {
        slide.querySelector('button').setAttribute('disabled', '')
      }
    })
  })
}

function sliderErrors(slider) {
  const slides = slider.slides

  for (let index = 0; index < slides.length; index++) {
    if (slides[index].hasAttribute('data-field-error')) {
      slider.slideTo(index, 0, false)
      break
    }
  }
}
