import $ from 'jquery'
import { Accordion } from './../../js/libraries/foundation'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const richtextAccordions = $('[data-richtext-accordion]')

  if (!richtextAccordions.length) return

  const richtextAccordionOptions = {
    allowAllClosed: true
  }

  richtextAccordions.each(function(index, richtextAccordion) {
    const richtextAccordionInit = new Accordion($(richtextAccordion), richtextAccordionOptions)
  })
}
