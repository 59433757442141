import $ from 'jquery'
import { Tabs } from './../../js/libraries/foundation'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  filters()
  archives()
}

function filters() {
  const controls = $('[data-controls-tabs]')

  if (!controls.length) return

  const controlsOptions = {
    activeCollapse: true
  }

  const controlsInit = new Tabs(controls, controlsOptions)
}

function archives() {
  const archivesContainer = document.querySelector('[data-archives]')

  if (!archivesContainer) return

  const archivesLabel = archivesContainer.querySelector('label')
  const targetURL = archivesContainer.getAttribute('data-target')

  archivesLabel.addEventListener('click', () => {
    window.location.replace(targetURL)
  })
}
