import Splitting from 'splitting'
import Swiper, { Pagination, EffectFade } from 'swiper'

Swiper.use([Pagination, EffectFade])

export default class sliderVideo {
  constructor(timeline) {
    this.timeline = timeline
    this.sliderContainer = timeline.querySelector('[data-timeline-slider]')
    this.videoContainer = timeline.querySelector('[data-timeline-animation]')
    this.paginationLabels = []
    this.animationSteps = [
      {
        start: 0,
        end: 4
      },
      {
        start: 4.1,
        end: 7.9
      },
      {
        start: 8.1,
        end: 12.9
      },
      {
        start: 13.2,
        end: 16.5
      },
      {
        start: 16.8,
        end: 21
      }
    ]

    this.videoDatas = {
      video: this.videoContainer.querySelector('video'),
      src: this.videoContainer.querySelector('video source'),
      baseSrc: this.videoContainer.querySelector('video source').getAttribute('src'),
      startTime: null,
      endTime: null
    }

    this.init()
  }

  textSplitting() {
    const slidesdescriptions = this.sliderContainer.querySelectorAll('.Timeline-slideText')

    slidesdescriptions.forEach(description => {
      Splitting({
        target: description,
        by: 'lines'
      })
    })
  }

  // get slides label to fill paginationLabels array with
  addPaginationLabels() {
    const sliderSlides = this.sliderContainer.querySelectorAll('.Timeline-slide')

    sliderSlides.forEach(slide => {
      this.paginationLabels.push(slide.getAttribute('data-duration'))
    })
  }

  // Lauch video only when Timeline Bloc is visible on viewport and put video on pause when video is out of viewport
  launchVideo() {
    const video = this.videoDatas.video

    if (!!window.IntersectionObserver) {
      let isPaused = true
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (!entry.isIntersecting && !video.paused) {
            video.pause()
            isPaused = true
          } else if (entry.isIntersecting && (isPaused)) {
            video.play()
            isPaused = false
          }
        })
      }, {
        rootMargin: '-180px 0px 0px 0px',
        threshold: 0.75
      })

      observer.observe(this.sliderContainer)
    }
  }

  // every time slide is changing, toggle block background-color
  colorChange(slider) {
    slider.on('slideChange', () => {
      this.timeline.classList.toggle('Timeline--lighten')
    })
  }

  // Init Timeline slider using Swiper
  sliderInit() {
    // Swiper options
    const sliderOptions = {
      allowTouchMove: false,
      effect: 'fade',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        // Custom pagination render using paginationLabels array
        renderBullet: (index, className) => {
          return `<button class="Timeline-paginationItem ${className}" aria-label="Aller à l\étape ${index+1}"><span class="Timeline-paginationLabel">${this.paginationLabels[index]}</span></button>`
        }
      }
    }

    const sliderInit = new Swiper(this.sliderContainer, sliderOptions)

    this.launchVideo()
    this.colorChange(sliderInit)

    // Play animation automatically by default
    if (this.sliderContainer.hasAttribute('data-slider-auto')) {
      this.sliderAuto(sliderInit)
    } else {
      this.sliderManual(sliderInit)
    }
  }

  // When animation is playing automatically, click on pagination allow to take control manually
  sliderControl(slider, interval) {
    const paginations = this.sliderContainer.querySelectorAll('.Timeline-paginationItem')

    paginations.forEach(pagination => {
      pagination.addEventListener('click', () => {
        if (this.sliderContainer.hasAttribute('data-slider-auto')) {
          this.sliderContainer.removeAttribute('data-slider-auto')

          clearInterval(interval)
          this.sliderManual(slider)
        }
      })
    })
  }

  // When user controls animation manually, this plays video only for the right section
  playVideo() {
    const canvas = this.videoContainer.querySelector('canvas')
    const newSrc = `${this.videoDatas.baseSrc}#t=${this.videoDatas.startTime},${this.videoDatas.endTime}`

    this.videoDatas.video.classList.add('invisible')
    canvas.classList.add('invisible')

    this.videoDatas.src.setAttribute('src', newSrc)

    this.videoDatas.video.load()

    this.videoDatas.video.onloadeddata = () => {
      this.videoDatas.video.classList.remove('invisible')
      canvas.classList.remove('invisible')
      this.videoDatas.video.play()
    }
  }

  // Animation is playing automatically by default and control the slider depending on his current time
  sliderAuto(slider) {
    let videoCurrentTime

    const interval = setInterval(() => {
      videoCurrentTime = this.videoDatas.video.currentTime

      for (let index = 0; index < this.animationSteps.length; index++) {
        const step = this.animationSteps[index]

        if (videoCurrentTime <= step.end) {
          if (slider.activeIndex != index) {
            slider.slideTo(index)
          }
          break
        }
      }

      if (this.videoDatas.video.ended) {
        clearInterval(interval)
      }
    }, 41)

    this.sliderControl(slider, interval)
  }

  // When user controls animation manually, each time slider is changing, animation is playing a specific section
  sliderManual(slider) {
    slider.on('slideChange', () => {
      if (this.animationSteps.length > slider.activeIndex) {
        this.videoDatas.startTime = this.animationSteps[slider.activeIndex].start
        this.videoDatas.endTime = this.animationSteps[slider.activeIndex].end
      }

      this.playVideo()
    })
  }

  init() {
    this.addPaginationLabels()
    this.textSplitting()
    this.sliderInit()
  }
}
