if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const body = document.body;
  const searchContainer = document.querySelector('[data-search-header]')

  if (!searchContainer) return
  const searchBlock = searchContainer.querySelector('[data-search-block]')
  const searchOpen = searchContainer.querySelector('[data-search-open]')
  const searchClose = searchContainer.querySelectorAll('[data-search-close]')
  const searchInput = searchContainer.querySelector('[data-search-input]')

  searchOpen.addEventListener('click', function() {
    body.classList.add('search-open')
    this.setAttribute('aria-expanded', 'true')
    searchBlock.setAttribute('aria-hidden', 'false')
    searchInput.focus()
  })

  searchClose.forEach(item => {
    item.addEventListener('click', () => {
      body.classList.remove('search-open')
      searchOpen.setAttribute('aria-expanded', 'false')
      searchBlock.setAttribute('aria-hidden', 'true')
    })
  })
}
