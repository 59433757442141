import scrollIntoView from 'scroll-into-view'

class anchorMenu {
  constructor(anchors, options) {
    this.anchors = anchors
    this.options = options
    this.main = document.querySelector('.main')

    this.init()
  }

  createMenuItem(linkText, linkHref, isActive = false) {
    const menuItem = document.createElement('li')
    const menuLink = document.createElement('a')

    menuLink.classList.add('AnchorMenu-link')
    menuLink.setAttribute('href', `#${linkHref}`)
    menuLink.innerText = linkText

    if (isActive) {
      menuLink.classList.add('AnchorMenu-link', 'is-active')
    }

    menuItem.classList.add('AnchorMenu-item')
    menuItem.appendChild(menuLink)

    return menuItem
  }

  addMenuItems(anchors, menuContent) {
    let isActive = false

    if (window.location.hash === '' || window.location.hash === `#${this.main.getAttribute('id')}`) {
      isActive = true
    }

    const summaryItem = this.createMenuItem(this.options.summaryButtonLabel, this.main.getAttribute('id'), isActive)

    menuContent.appendChild(summaryItem)

    anchors.forEach(anchor => {
      isActive = false
      const anchorId = anchor.getAttribute('id')
      const anchorText = anchor.innerText

      if (window.location.hash === '#' + anchorId) {
        isActive = true
      }

      const newItem = this.createMenuItem(anchorText, anchorId, isActive)

      menuContent.appendChild(newItem)
    })
  }

  createMenu() {
    const menu = document.createElement('section')
    const menuDropdownButton = document.createElement('button')
    const menuContent = document.createElement('ul')

    menuDropdownButton.classList.add('AnchorMenu-toggle')
    menuDropdownButton.innerText = this.options.summaryButtonLabel
    menuDropdownButton.setAttribute('data-index', '1')
    menuDropdownButton.setAttribute('data-total-index', this.anchors.length + 1)
    menuContent.classList.add('AnchorMenu-content')
    menu.classList.add('AnchorMenu')
    menu.appendChild(menuDropdownButton)
    menu.appendChild(menuContent)

    this.addMenuItems(this.anchors, menuContent)
    this.addMenuToDOM(menu)
  }

  addMenuToDOM(element) {
    const mainContainer = document.querySelector('.main')
    const richtext = document.querySelector('.RichText')

    mainContainer.insertBefore(element, richtext)
  }

  onScroll() {
    const windowMiddle = window.innerHeight / 2
    const anchorMenu = document.querySelector('.AnchorMenu')
    const anchorMenuToggle = anchorMenu.querySelector('.AnchorMenu-toggle')
    const anchorMenuLinks = anchorMenu.querySelectorAll('.AnchorMenu-link')
    const summaryItem = anchorMenu.querySelector('a[href="#main"]')

    let headerHeight = document.querySelector('.Header-top').offsetHeight
    let heroBottomPosition
    let richtextBottomPosition

    window.addEventListener('scroll', () => {
      heroBottomPosition = document.querySelector('.Hero').getBoundingClientRect().bottom
      richtextBottomPosition = document.querySelector('.RichText').getBoundingClientRect().bottom

      if (window.pageYOffset < 150) {
        anchorMenuLinks.forEach(anchorMenuLink => {
          anchorMenuLink.classList.remove('is-active')
        })

        summaryItem.classList.add('is-active')

        anchorMenuToggle.innerText = this.options.summaryButtonLabel
        anchorMenuToggle.setAttribute('data-index', 1)
      }

      if (heroBottomPosition < headerHeight) {
        anchorMenu.classList.add('AnchorMenu--is-sticked')
      } else {
        anchorMenu.classList.remove('AnchorMenu--is-sticked')
      }

      if (richtextBottomPosition < windowMiddle) {
        anchorMenu.classList.add('AnchorMenu--is-invisible')
      } else {
        anchorMenu.classList.remove('AnchorMenu--is-invisible')
      }
    })
  }

  onMenuClick() {
    const anchorMenu = document.querySelector('.AnchorMenu')
    const anchorMenuToggle = anchorMenu.querySelector('.AnchorMenu-toggle')
    const anchorMenuLinks = Array.from(anchorMenu.querySelectorAll('.AnchorMenu-link'))

    anchorMenuToggle.addEventListener('click', () => {
      anchorMenuToggle.classList.toggle('menu-open')
    })

    anchorMenuLinks.forEach(anchorMenuLink => {
      anchorMenuLink.addEventListener('click', (e) => {
        e.preventDefault()

        const destination = e.target.getAttribute('href')

        scrollIntoView(document.querySelector(destination), {
          time: 1000,
          ease: function(value){
            return 1 - Math.pow(1 - value, value / 5);
          },
          align: {
            top: 0
          }
        }, () => {
          location.hash = destination
        })

        if (anchorMenuToggle.classList.contains('menu-open')) {
          anchorMenuToggle.classList.remove('menu-open')
        }
      })
    })
  }

  scrollSpy() {
    const anchors = this.anchors
    const anchorMenu = document.querySelector('.AnchorMenu')
    const anchorMenuToggle = anchorMenu.querySelector('.AnchorMenu-toggle')
    const anchorMenuLinks = Array.from(anchorMenu.querySelectorAll('.AnchorMenu-link'))

    let anchorPosition

    const observerOptions = {
      rootMargin: '0px 0px -80% 0px'
    }

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          anchorMenuLinks.forEach(anchorMenuLink => {
            anchorMenuLink.classList.remove('is-active')

            if (anchorMenuLink.getAttribute('href') === `#${entry.target.getAttribute('id')}`) {
              anchorMenuLink.classList.add('is-active')

              anchorPosition = anchorMenuLinks.findIndex((element) => element === anchorMenu.querySelector(`a[href="#${entry.target.getAttribute('id')}"]`)) + 1

              anchorMenuToggle.innerText = entry.target.innerText
              anchorMenuToggle.setAttribute('data-index', anchorPosition)
            }
          })
        }
      })
    }, observerOptions)

    anchors.forEach(anchor => {
      observer.observe(anchor)
    })
  }

  init() {
    this.createMenu()
    this.onScroll()
    this.onMenuClick()
    this.scrollSpy()
  }
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const anchors = document.querySelectorAll('body.article .ez-has-anchor')

  if (!anchors.length) return

  const anchorMenuOptions = {
    summaryButtonLabel: 'Sommaire'
  }

  const anchorMenuInit = new anchorMenu(anchors, anchorMenuOptions)
}
