export default class videoTransparency {
  constructor(container) {
    this.container = container
    this.video = container.querySelector('video')
    this.canvas = container.querySelector('canvas')
    this.context = this.canvas.getContext('2d')

    this.size = {
      width: this.video.getAttribute('width'),
      height: this.video.getAttribute('height')
    }

    this.init()
  }

  // get a video frame, draw it in a canvas and make each white pixel (or close) transparent
  computeFrame() {
    this.context.drawImage(this.video, 0, 0, this.size.width, this.size.height)

    const frame = this.context.getImageData(0, 0, this.size.width, this.size.height)
    const dataLength = frame.data.length / 4

    for (let i = 0; i < dataLength; i++) {
      let r = frame.data[i * 4 + 0]
      let g = frame.data[i * 4 + 1]
      let b = frame.data[i * 4 + 2]

      if (r > 220 && g > 220 && b > 220) {
        frame.data[i * 4 + 3] = 0
      }
    }

    this.context.putImageData(frame, 0, 0)

    return
  }

  // render video frame on canvas every 16ms to simulate video playing
  timerCallback() {
    if (this.video.paused || this.video.ended) return

    this.computeFrame()

    setTimeout(() => {
      this.timerCallback()
    }, 41)
  }

  loadVideo() {
    this.video.addEventListener('play', () => {
      this.timerCallback()
    })
  }

  init() {
    this.loadVideo()
  }
}
