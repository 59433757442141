if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const termsDropdown = document.querySelector('[data-terms-dropdown]')

  if (!termsDropdown) return

  const termsToggle = termsDropdown.querySelector('[data-terms-toggle]')
  const termsContent = termsDropdown.querySelector('[data-terms-content]')

  termsToggle.addEventListener('click', function() {
    if (this.getAttribute('aria-expanded') === 'false') {
      this.setAttribute('aria-expanded', 'true')
      this.setAttribute('aria-label', 'Voir moins')
    } else {
      this.setAttribute('aria-expanded', 'false')
      this.setAttribute('aria-label', 'Lire la suite')
    }

    if (termsContent.getAttribute('aria-hidden') === 'true') {
      termsContent.setAttribute('aria-hidden', 'false')
    } else {
      termsContent.setAttribute('aria-hidden', 'true')
    }

    termsContent.classList.toggle('is-open')
  })
}
