if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const richtextVideos = document.querySelectorAll('[data-kaliop-player] video')

  if (!richtextVideos.length) return

  if (typeof ATTag === 'undefined') {
    var ATTag = new ATInternet.Tracker.Tag();
  }

  richtextVideos.forEach(video => {
    const player = video.closest('[data-kaliop-player]')
    const playerWidget = player.querySelector(".KaliopPlayer-player")
    const playerData = JSON.parse(player.getAttribute('data-kaliop-player'))
    const currentMediaData = playerData.medias[playerWidget.getAttribute('data-current-media-index')]

    video.addEventListener('canplay', () => {
      ATTag.richMedia.add({
        mediaType: playerData.options.mediaType !== undefined ? playerData.options.mediaType : 'video',
        playerId: currentMediaData.id,
        mediaLabel: currentMediaData.title,
        mediaTheme1: currentMediaData.theme,
        refreshDuration: {
          0: 5,
          1: 15,
          5: 30,
          10: 60
        },
        duration: currentMediaData['duration_in_second'],
        isEmbedded: false,
        broadcastMode: 'clip'
      })
    })

    video.addEventListener('play', () => {
      ATTag.richMedia.send({
        playerId: currentMediaData.id,
        mediaLabel: currentMediaData.title,
        mediaTheme1: currentMediaData.theme,
      })
    })

    video.addEventListener('pause', function() {
      ATTag.richMedia.send({
        action: 'pause',
        playerId: currentMediaData.id,
        mediaLabel: currentMediaData.title,
        mediaTheme1: currentMediaData.theme,
      })
    })
  })
}
