import $ from 'jquery'
import { Accordion } from './../../js/libraries/foundation'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  accordion()
  showMore()
}

function accordion() {
  const faqAccordion = $('[data-faq-accordion]')

  if (!faqAccordion.length) return

  const faqAccordionOptions = {
    allowAllClosed: true,
    slideSpeed: 350
  }

  const faqAccordionInit = new Accordion(faqAccordion, faqAccordionOptions)
}

function showMore() {
  const moreButton = document.querySelector('[data-faq-more] .Button')
  const hiddenAccordions = document.querySelectorAll('.FaqAccordion-item.is-invisible')

  if (!moreButton || !hiddenAccordions.length) return

  moreButton.addEventListener('click', function() {
    hiddenAccordions.forEach(hiddenAccordion => {
      hiddenAccordion.classList.remove('is-invisible')
      this.parentNode.remove()
    })
  })
}
