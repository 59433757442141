import flatpickr from "flatpickr"
import { French } from "flatpickr/dist/l10n/fr"

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  datepickersInit()
  structureFormButton()
}

function datepickersInit() {
  // Replace all date inputs by Flatpicker
  const dateInputs = document.querySelectorAll('input[data-input-type="date"]')

  if (!dateInputs.length) return

  flatpickr.localize(French)

  const dateInputsOptions = {
    dateFormat: 'd/m/Y',
    maxDate: "today",
    locale: {
      "firstDayOfWeek": 1
    },
    disableMobile: true
  }

  dateInputs.forEach(dateInput => {

    const disabledDay = dateInput.getAttribute('data-date-disabled-day');
    if(disabledDay) {
      dateInputsOptions.disable = [
        function (date) {
          return (date.getDay() === parseInt(disabledDay))
        }
      ]
    }

    const maxDate = dateInput.getAttribute('data-date-max-date');
    if(maxDate) {
      dateInputsOptions.maxDate = new Date().fp_incr(maxDate)
    }

    const minDate = dateInput.getAttribute('data-date-min-date');
    if(minDate) {
      dateInputsOptions.minDate = minDate
    }

    const dateInputInit = flatpickr(dateInput, dateInputsOptions)

    dateInputsOptions.maxDate = 'today'
    dateInputsOptions.disable = undefined
  })
}

function structureFormButton() {
  const form = document.querySelector('#form_structure')

  if (!form) return

  const checkbox = form.querySelector('#structure_registration_diploma')
  const button = form.querySelector('#structure_registration_submit')

  if(checkbox.checked) {
    button.removeAttribute('disabled')
  }

  checkbox.addEventListener('change', () => {
    if(checkbox.checked) {
      button.removeAttribute('disabled')
    } else {
      button.setAttribute('disabled', '')
    }
  })
}
