import $ from 'jquery'
import { Accordion } from './../../js/libraries/foundation'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const resultAccordions = $('[data-result-accordion]')

  if (!resultAccordions.length) return

  const resultAccordionOptions = {
    allowAllClosed: true,
    slideSpeed: 350
  }

  resultAccordions.each(function(index, resultAccordion) {
    const resultAccordionInit = new Accordion($(resultAccordion), resultAccordionOptions)
  })

}
