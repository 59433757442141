if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

// Mobile menu toggle function
function init() {
  const body = document.body;
  const menuButton = document.querySelector('[data-menu-toggle]');
  const menuOverlay = document.querySelector('[data-menu-overlay]');

  if (!menuButton || !menuOverlay) return;

  menuButton.addEventListener('click', function() {
    this.classList.toggle('nav-open');
    body.classList.toggle('nav-open');
  });

  menuOverlay.addEventListener('click', () => {
    menuButton.classList.remove('nav-open');
    body.classList.remove('nav-open');
  });
}
