import Swiper, { Navigation, Pagination, EffectFade } from 'swiper'

Swiper.use([Navigation, Pagination, EffectFade])

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const testimonialsCarousel = document.querySelector('[data-testimonials-slider]')

  if (!testimonialsCarousel) return

  const testimonialsdropdown = testimonialsCarousel.querySelectorAll('[data-testimonial-dropdown]')

  let testimonialsCarouselOptions = {
    slidesPerView: 'auto',
    centeredSlides: true,
    pagination: {
      el: '.TestimonialsCarousel-pagination',
      type: 'bullets'
    }
  }

  if (Foundation.MediaQuery.atLeast('medium')) {
    testimonialsCarouselOptions = {
      slidesPerView: 'auto',
      loop: true,
      effect: 'fade',
      allowTouchMove: false,
      navigation: {
        nextEl: '.TestimonialsCarousel-nav--next',
        prevEl: '.TestimonialsCarousel-nav--previous',
      },
      pagination: {
        el: '.TestimonialsCarousel-pagination',
        type: 'bullets',
        clickable: true
      }
    }
  }

  const testimonialsCarouselInit = new Swiper(testimonialsCarousel, testimonialsCarouselOptions)

  testimonialsCarouselInit.on('slideChange', () => {
    testimonialsdropdown.forEach(testimonial => {
      if (testimonial.classList.contains('Testimonial-dropdown--open')) {
        testimonial.classList.remove('Testimonial-dropdown--open')
        testimonial.querySelector('.Testimonial-moreButton').setAttribute('aria-label', 'Lire la suite')
      }
    })
  })
}
