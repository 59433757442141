import throttle from './../../js/global/throttle'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  headerReduced()
}

function headerReduced() {
  let scrollPos = 0;
  let lastScrollPos = 0;

  window.addEventListener('scroll', throttle(function() {
    scrollPos = window.pageYOffset;

    if (scrollPos > 150 && scrollPos > lastScrollPos) {
      document.body.classList.add('header-reduced');
    } else {
      document.body.classList.remove('header-reduced');
    }

    lastScrollPos = scrollPos <= 0 ? 0 : scrollPos; // For Mobile or negative scrolling
  }, 100));
}

