import { listParams, store } from './map'
import { fetchStructures } from './mapfetchstructures'
import { map } from './mapfunctions'


function onFilterClick(e) {
  const button = e.currentTarget

  if (button.classList.contains('is-selected')) {
    store.filters = store.filters.filter((item) => {
      return item !== button.innerText
    })
    listParams.currentPage = 0
  } else if (!store.filters.find(item => item === button.innerText)) {
    store.filters.push(button.innerText)
    listParams.currentPage = 0
  }
  button.classList.toggle('is-selected')
  map.setCenter(store.startLocation)
  fetchStructures()
}

export function initFilters() {
  const buttons = document.querySelectorAll('[data-controls] [data-controls-button]')

  if(!buttons.length) return

  buttons.forEach(button =>
    button.addEventListener('click', onFilterClick)
  )
}

export function updateSearchTitle() {
  const titleContainer = document.querySelector('[data-map-filtersTitle]')

  if (!titleContainer) return

  let titleHtml

  if (store.listedStructureCount > 1) {
    titleHtml = `<h2 class="h1-style">Voici les ${store.listedStructureCount} structures les plus proches</h2>`
  } else {
    titleHtml = `<h2 class="h1-style">Voici la structure la plus proche</h2>`
  }

  titleContainer.innerHTML = titleHtml
}
