import Swiper, { Navigation, Pagination } from 'swiper'

Swiper.use([Navigation, Pagination])

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const TISESliders = document.querySelectorAll('[data-tiseSlider]')

  if (!TISESliders.length) return

  TISESliders.forEach(slider => {
    const sliderOptions = {
      spaceBetween: 30,
      loop: true,
      watchOverflow: true,
      grabCursor: true,
      navigation: {
        nextEl: slider.querySelector('.TISESlider-nav--next'),
        prevEl: slider.querySelector('.TISESlider-nav--previous'),
      },
      pagination: {
        el: slider.querySelector('.swiper-pagination'),
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        1024: {
          spaceBetween: 150
        }
      }
    }

    const sliderInit = new Swiper(slider, sliderOptions)
  })
}
