import $ from 'jquery'
import { DropdownMenu } from './../../js/libraries/foundation'

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const menu = $('[data-menu]')

  if (!menu.length) return

  let menuOptions

  if (Foundation.MediaQuery.upTo('large')) {
    menuOptions = {
      disableHover: true,
      clickOpen: true
    }
  } else {
    menuOptions = {
      hoverDelay: 250,
      closingTime: 100
    }
  }

  const menuInit = new DropdownMenu(menu, menuOptions)
}
