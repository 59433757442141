import $ from 'jquery'
import { Sticky } from './../../js/libraries/foundation'

const mapContainer = $('[data-results-map]')

export function mapStick() {
  if (!mapContainer.length) return

  const mapStickyOptions = {
    anchor: 'MapAnchor',
    marginTop: 5,
    marginBottom: 0,
    containerClass: 'MapResults-mapContainer',
    stickyOn: 'large'
  }

  const mapStickyInit = new Sticky(mapContainer, mapStickyOptions)
}

export function mapStickReload() {
  if (!mapContainer.length) return

  Foundation.reInit(mapContainer)
}
