import Bowser from "bowser"

const siteBody = document.body
const browser = Bowser.getParser(window.navigator.userAgent)

// Get the name of current browser
const browserName = browser.getBrowserName().toLowerCase()

// Get platform type ('desktop', 'tablet', 'mobile')
const platformType = browser.getPlatformType().toLowerCase()

// Add current browser's name into body's classList
siteBody.className += ` ${browserName} ${platformType}`
