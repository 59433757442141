// ------
// Styles
// ------
import "./styles/index.scss"


// ---------
// Polyfills
// ---------
import './js/polyfills/polyfill'


// ---------
// Libraries
// ---------
import $ from "jquery" //jQuery must be used for Foundation components
import kaliopMediaPlayerHandler from "kaliop-media-player";
import "what-input"
import "./js/libraries/foundation"
import "./js/global/bowser"

// Init Foundation (need jquery)
window.addEventListener('load', () => {
  $(document).foundation()
});


// ------------------
// Components scripts
// ------------------
import "./components/anchormenu/anchormenu";
import "./components/callback/callback";
import "./components/faqaccordion/faqaccordion";
import "./components/form/form";
import "./components/header/header";
import "./components/internalmesh/internalmesh";
import "./components/controls/controls";
import "./components/map/map.js";
import "./components/menu/menu";
import "./components/menu/menubutton/menubutton";
import "./components/richtext/richtext";
import "./components/richtextvideo/richtextvideo";
import "./components/searchautocomplete/searchautocomplete";
import "./components/searchheader/searchheader";
import "./components/searchresults/searchresults";
import "./components/terms/terms";
import "./components/testimonial/testimonial";
import "./components/testimonialscarousel/testimonialscarousel";
import "./components/timeline/timeline";

// Specific components for TIS Employer static site
import "./components/tisemployers/slider/slider";

kaliopMediaPlayerHandler.init()
