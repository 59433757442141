import Swiper, { Navigation } from 'swiper'

Swiper.use([Navigation])

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}

function init() {
  const internalMeshSliders = document.querySelectorAll('[data-internalmesh-slider]')

  if (!internalMeshSliders.length) return

  internalMeshSliders.forEach(slider => {
    const sliderOptions = {
      speed: 450,
      slidesPerView: 'auto',
      watchOverflow: true,
      grabCursor: true,
      navigation: {
        nextEl: slider.parentNode.querySelector('.swiper-button-next'),
        prevEl: slider.parentNode.querySelector('.swiper-button-prev'),
      }
    }

    const sliderInit = new Swiper(slider, sliderOptions)
  })
}
